import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import { withForceUpdate } from '../i18n/with-force-update';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const NotFoundPage = ({ t, pageContext: { language } }) => {
  const data = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "404.png" }) {
        childImageSharp {
          gatsbyImageData(width: 200, height: 300, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
        }
      }
    }
  `);

  return (
    <Layout title={t('404 Page not found')} showTitle lang={language}>
      <h3 css={{ color: `grey`, textAlign: `center`, fontFamily: `LatoLatinWebLight`, fontWeigth: 200 }}>
        {t("Seems like the page you're looking for has moved or does not exist.")}
      </h3>
      <div css={{ width: `100%`, textAlign: `center` }}>
        <GatsbyImage css={{ margin: `10px auto` }} image={getImage(data.cover)} loading="eager" />
      </div>
    </Layout>
  );
};

export default withForceUpdate(NotFoundPage);
